import { FilterSelectItem } from '@infosysbub/ng-lib-dpl3';

export enum SearchType {
  suchwort,
  studienfach,
  studienfeld
}

export class SelectItem {
  constructor(
    public key: string,
    public value: any,
    public label: string,
    public selected: boolean,
    public active: boolean,
    public treffer?: number,
    public type?: SearchType,
    public anbietername?: string
  ) {}

  static fromFilterSelectItem(item: FilterSelectItem): SelectItem {
    return new SelectItem(
      item.key,
      {
        trefferAnzahl: item.treffer,
        preset: item.selected,
        id: item.key,
        label: item.label
      },
      `${item.label} (<span class=\"cnt\" title=''>${item.treffer}</span>)`,
      item.selected,
      item.active
    );
  }

  toFilterSelectItem(modal = null): FilterSelectItem {
    return {
      key: this.key,
      ariaLabel: this.label,
      treffer: this.value.trefferAnzahl,
      label: this.value.label,
      selected: this.selected,
      active: this.active,
      modal
    } as FilterSelectItem;
  }
}
