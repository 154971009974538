import { ChangeDetectorRef, Injectable } from '@angular/core';
import { LoggingService, SelectedSuggestion, Suggestion, TagItem } from '@infosysbub/ng-lib-dpl3';
import { BehaviorSubject, Observable, Subscription, of as observableOf, of } from 'rxjs';
import { catchError, distinctUntilChanged, map } from 'rxjs/operators';
import { StudisuErrorService } from '../../error/studisuerror.service';
import { UrlParamService } from '../../main/suche/services/url-param.service';
import { Messages } from '../../ui-components/model/Messages';
import { SearchType } from '../../ui-components/model/SelectItem';
import { Studienfach } from '../suche/services/model/Studienfach';
import { Suchtyp } from '../suche/services/model/Suchtyp';
import { StudienfachService } from '../suche/services/studienfach.service';
import { Studienfeld } from './services/model/Studienfeld';
import { Studienfeldgruppe } from './services/model/Studienfeldgruppe';
import { StudienfelderService } from './services/studienfelder.service';

// Könnte ruhig mal refactored werden. Ist IHMO unnötig komplex und nicht sonderlich clean.
/**
 * Das Delegate erlaubt das Laden und die Verwendung von Studienfeldgruppen und Studienfeldern.
 *
 * Die bereitgestellten Methoden können in den Livecycle-Hooks der nutzenden Komponenten
 * aufgerufen werden und erlauben so die Wiederverwendung des Codes dieses Delegates.
 *
 * Insbesondere wird die URL mit einer Auswahl von Studienfeldern synchron gehalten, was sowohl
 * auf der Sucheseite als auch auf der Seite zur Auswahl der Studienfelder verwendet wird.
 */
export const MAX_STUDIENBEREICH_PARAMS = 40;

@Injectable()
export class StudienfelderDelegate {
  private static AUTOSUGGEST_MINLEN = 2;
  private static VALIDATE_REGEX =
    '^(\\s*|[\\sa-zA-ZäöüàâæçèéêëîïôœùûÿÀÂÆÇÈÉÊËÎÏÔŒÙÛŸÄÖÜß/().,-]+)$';

  public studienfeldgruppen: Studienfeldgruppe[] = [];
  public selektierteStudienfelder: Studienfeld[] = [];

  public selektierteStudienfaecher = [];

  public removeTooltip = Messages.TAG_STUDIENFELD_TOOLTIP_REMOVE;

  public isReady = new BehaviorSubject(false);

  private urlParamSubscription: Subscription;
  private studienfeldSubscription: Subscription;

  /**
   * Constructor der Komponente mit injizierten Services.
   *
   * @param urlParamService Injected Service.
   * @param studienfelderService Injected Service.
   * @param studienfachService Injected Service.
   * @param errorDialogService Injected Service zur Fehlerhaltung
   * @param loggingService Injected Service.
   */
  constructor(
    private urlParamService: UrlParamService,
    private studienfelderService: StudienfelderService,
    private studienfachService: StudienfachService,
    private studisuErrorService: StudisuErrorService,
    private loggingService: LoggingService,
    private changedetector: ChangeDetectorRef
  ) {}

  public get isMaxParameterCountExceeded(): boolean {
    return (
      this.selektierteStudienfaecher.length + this.selektierteStudienfelder.length >=
      MAX_STUDIENBEREICH_PARAMS
    );
  }

  public get autosuggestMinLen(): number {
    return StudienfelderDelegate.AUTOSUGGEST_MINLEN;
  }

  public get maxParameterCountExceededErrorMessage(): string {
    return Messages.ZU_VIELE_STUDIENFAECHER.replace('%max%', '' + MAX_STUDIENBEREICH_PARAMS);
  }

  /**
   * Initialisierung der Komponente
   * Hier wird der zugehoerige Url Parameter ausgelesen und
   * verarbeitet und der Studienfeld-Service aufgerufen
   */
  public init() {
    // Die Studienfelder werden nur 1x geladen, daher am Ende gleich ein unsubscribe()!
    this.studienfeldSubscription = this.studienfelderService.getStudienfelder().subscribe(
      (result) => {
        // Laden der Studienfeldgruppen aus dem Ergebnis des Subscription.
        this.ladeStudienfeldgruppen(result);

        // Wir subscriben für URL-Änderungen, nachdem wir die Studienfeldgruppen geladen haben.
        // Dadurch brauchen wir keine Fallunterscheidung für Race-Conditions!
        this.urlParamSubscription = this.urlParamService.currentParams
          .pipe(
            map((params) => {
              return {
                studienfaecher: params.get(UrlParamService.PARAM_STUDIENFAECHER),
                studienfelder: params.get(UrlParamService.PARAM_STUDIENFELDER)
              };
            }),
            distinctUntilChanged()
          )
          .subscribe((studienfelder) => {
            this.parseUrlParams(studienfelder);

            this.changedetector.detectChanges();
          });
      },
      (err) => {
        // STUDISU-105 zeige Fehlerdialog
        this.studisuErrorService.pushError(Messages.FEHLER_LADEN_DATEN, err);
      }
    );
  }

  /**
   *  Objekt nimmt sich aus den Subscriptions raus
   */
  public destroy() {
    if (this.studienfeldSubscription) {
      this.studienfeldSubscription.unsubscribe();
    }
    if (this.urlParamSubscription) {
      this.urlParamSubscription.unsubscribe();
    }
  }

  /**
   * Lade-Funktion fuer die Auto-Complete-Komponente.
   * @param {string} query der Such-String
   * @returns {SelectItem[]} die Auto-Complete-Verschlaege
   */
  public loadSuggestions(queryInput: string, suchtyp: Suchtyp) {
    if (suchtyp.type === SearchType.studienfach) {
      return this.loadSuggestionsStudienfaecher(queryInput);
    }
    if (suchtyp.type === SearchType.studienfeld) {
      return this.loadSuggestionsStudienfelder(queryInput);
    }
  }

  public loadSuggestionsStudienfaecher(queryInput: string): Observable<Suggestion[]> {
    let query = queryInput.trim();

    if (query.length < StudienfelderDelegate.AUTOSUGGEST_MINLEN) {
      return observableOf([]);
    }

    let valid = new RegExp(StudienfelderDelegate.VALIDATE_REGEX, 'i').test(query);
    if (!valid) {
      return observableOf([Suggestion.error(Messages.STUDIENBEREICHE_EINGABEPARAMETER_FEHLERHAFT)]);
    }

    return this.studienfachService.suchen(query).pipe(
      map((studienfaecher: Studienfach[]) => {
        if (studienfaecher.length === 0) {
          return [Suggestion.error(Messages.KEINE_VORSCHLAEGE_GEFUNDEN)];
        }
        return studienfaecher.map((sf) => Suggestion.of(sf.label, sf, null));
      }),
      catchError((err) => {
        this.loggingService.error(Messages.FEHLER_LADEN_STUDIENBEREICH, this);
        return observableOf([Suggestion.error(Messages.FEHLER_LADEN_STUDIENBEREICH)]);
      })
    );
  }

  public loadSuggestionsStudienfelder(queryInput: string): Observable<Suggestion[]> {
    let query = queryInput.trim();

    if (query.length < StudienfelderDelegate.AUTOSUGGEST_MINLEN) {
      return observableOf([]);
    }

    let valid = new RegExp(StudienfelderDelegate.VALIDATE_REGEX, 'i').test(query);
    if (!valid) {
      return observableOf([Suggestion.error(Messages.STUDIENFELDER_EINGABEPARAMETER_FEHLERHAFT)]);
    }

    return this.studienfelderService.suchenAc(query).pipe(
      map((studienfelder: Studienfeld[]) => {
        if (studienfelder.length === 0) {
          return [Suggestion.error(Messages.KEINE_VORSCHLAEGE_GEFUNDEN)];
        }
        return studienfelder.map((sf) => Suggestion.of(sf.label, sf, null));
      }),
      catchError((err) => {
        this.loggingService.error(Messages.FEHLER_LADEN_STUDIENFELDER, this);
        return observableOf([Suggestion.error(Messages.FEHLER_LADEN_STUDIENFELDER)]);
      })
    );
  }

  /**
   * Fügt die Auswahl aus dem Autosuggest als Suchwort hinzu.
   *
   * @param auswahl Das aus dem Auto-Suggest ausgewählte Item.
   */
  public addAuswahl(auswahl: SelectedSuggestion) {
    if (auswahl.value instanceof Studienfach) {
      let studienfach = auswahl.value;
      let index = this.selektierteStudienfaecher.findIndex(
        (x: Studienfach) => x.key === studienfach.key
      );
      // Studienfach in der Vorschlagsliste, aber noch nicht in der Auswahlliste?
      if (studienfach != null && index === -1) {
        this.selektierteStudienfaecher.push(studienfach);
        this.updateUrlParamFach();
      }
    } else if (auswahl.value instanceof Studienfeld) {
      let studienfeld = auswahl.value;
      let index = this.selektierteStudienfelder.findIndex(
        (x: Studienfeld) => x.key === studienfeld.key
      );
      // Studienfeld in der Vorschlagsliste, aber noch nicht in der Auswahlliste?
      if (studienfeld != null && index === -1) {
        this.selektierteStudienfelder.push(studienfeld);
        this.updateUrlParamFeld();
      }
    }
  }

  /**
   * Aktualisiert die aktuelle Selektion von Studienfeldern mit dem UrlParamService.
   */
  public updateUrlParamFeld() {
    let urlParamFelder: string = this.selektierteStudienfelder
      // map / bilde ab von Studienfelder[] auf Array of Number[]
      .map((studienfeld: Studienfeld) => studienfeld.dkzIds)
      // reduziere Number[] dkzIds auf EIN Number[] ALLER dkzIds
      .reduce((previousValue: number[], dkzIds: number[]) => previousValue.concat(dkzIds), [])
      // join / stringconcateniere das Array aller dkzIds zu einem String mit angegebenem trennzeichen
      .join(UrlParamService.VALUE_SEPARATOR);
    this.urlParamService.updateView({
      [UrlParamService.PARAM_STUDIENFELDER]: urlParamFelder,
      [UrlParamService.PARAM_PAGE]: 1
    });
  }

  /**
   * Aktualisiert die aktuelle Selektion von Studienfaechern mit dem UrlParamService.
   * analog zu updateUrlParamFeld
   */
  public updateUrlParamFach() {
    let urlParamFaecher: string = this.selektierteStudienfaecher
      // map / bilde ab von Studienfach[] auf Number[]
      .map((studienfach: Studienfach) => studienfach.dkzId)
      // join / stringconcateniere das Array der dkzId zu einem String mit angegebenem trennzeichen
      .join(UrlParamService.VALUE_SEPARATOR);
    this.urlParamService.updateView({
      [UrlParamService.PARAM_STUDIENFAECHER]: urlParamFaecher,
      [UrlParamService.PARAM_PAGE]: 1
    });
  }

  /**
   * Setzt die Suche bezüglich Studienfeldern und Studienfaechern sowie gewaehlter Facetten vollständig zurück.
   * Behalten werden nur die Suchworte für Regionen.
   */
  public bereinigeStudienbereicheFilter() {
    this.urlParamService.resetFilter();
    this.urlParamService.updateView({
      [UrlParamService.PARAM_STUDIENFAECHER]: null,
      [UrlParamService.PARAM_STUDIENFELDER]: null,
      [UrlParamService.PARAM_PAGE]: null
    });
  }

  public clickHandler(item: any) {
    if (item && item instanceof Studienfeld && item.clickable) {
      this.gotoStudienfeld(item);
    } else if (item && item instanceof Studienfach && item.clickable) {
      this.gotoStudienfach(item);
    }
  }

  public generateLink(item: any) {
    if (item && item instanceof Studienfeld && item.clickable) {
      // Bei Studienfeld die erforderlichen Parameter für Weiterleitung erfragen und querystring erstellen
      return of(
        'studienfeldinfo?' +
          this.urlParamService
            .getUrlParamsForFrontend()
            .set(
              UrlParamService.PARAM_STUDIENFELDER,
              item.dkzIds.join(UrlParamService.VALUE_SEPARATOR)
            )
            .set(UrlParamService.PARAM_PAGE, null)
            .toString()
      );
    } else if (item && item instanceof Studienfach && item.clickable) {
      // Bei Studienfach die zugehörigen Studienfelder und Parameter erfragen und querystring erstellen,
      let studienfachDkzId = item.dkzId;
      return this.studienfelderService.getStudienfelderForStudienfach(studienfachDkzId).pipe(
        map((studienbereichDkzIds) => {
          return (
            'studienfeldinfo?' +
            this.urlParamService
              .getUrlParamsForFrontend()
              .set(
                UrlParamService.PARAM_STUDIENFELDER,
                studienbereichDkzIds.join(UrlParamService.VALUE_SEPARATOR)
              )
              .set(UrlParamService.PARAM_STUDIENFAECHER, studienfachDkzId.toString())
              .set(UrlParamService.PARAM_PAGE, '1')
              .toString()
          );
        })
      );
    }
    return of('');
  }

  public gotoStudienfeld(studienfeld: Studienfeld) {
    this.urlParamService.navigateTo('studienfeldinfo', {
      [UrlParamService.PARAM_STUDIENFELDER]: studienfeld.dkzIds.join(
        UrlParamService.VALUE_SEPARATOR
      ),
      [UrlParamService.PARAM_PAGE]: null
    });
  }

  /**
   * De-selektiert ein Studienfeld oder -fach.
   *
   * @param item Das zu entfernende Item.
   */
  public remove(item: any) {
    if (item instanceof Studienfach) {
      this.removeFach(item);
    } else if (item instanceof Studienfeld) {
      this.removeFeld(item);
    }
  }
  public removeTag(item: TagItem) {
    this.remove(item?.value);
  }

  // Beim Click auf ein Tag mit Studienfach, wird diese Methode aufgerufen
  // Zum ausgewählten Studienfach, werden Studienfelder über ein Service ermittelt und
  // auf die Studienfeld-Info Seite umgeleitet
  private gotoStudienfach(studienfach: Studienfach) {
    let studienfachDkzId = studienfach.dkzId;
    let studienbereichDkzIds =
      this.studienfelderService.getStudienfelderForStudienfach(studienfachDkzId);
    studienbereichDkzIds.subscribe((x) => {
      this.urlParamService.navigateTo('studienfeldinfo', {
        [UrlParamService.PARAM_STUDIENFELDER]: x.join(UrlParamService.VALUE_SEPARATOR),
        [UrlParamService.PARAM_STUDIENFAECHER]: studienfachDkzId,
        [UrlParamService.PARAM_PAGE]: null
      });
    });
  }

  /**
   * Callback u.a. für die Checkbox, selektiert oder de-selektiert ein Studienfeld.
   *
   * @param studienfeld Das umzuschaltende Studienfeld
   */
  private removeFeld(studienfeld: Studienfeld) {
    let index = this.selektierteStudienfelder.indexOf(studienfeld);
    if (index < 0) {
      this.selektierteStudienfelder.push(studienfeld);
    } else {
      this.selektierteStudienfelder.splice(index, 1);
    }

    // STUDISU-45
    // falls der letzte studienbereich/das letzte suchwort geloescht wurde,
    // sollen die selektierten facettenfilter verworfen werden
    if (
      !this.urlParamService.isSuchwortSet() &&
      this.selektierteStudienfelder.length + this.selektierteStudienfaecher.length === 0
    ) {
      this.urlParamService.resetFilter();
    }

    this.updateUrlParamFeld();
  }

  /**
   * Callback u.a. für die Checkbox, selektiert oder de-selektiert ein Studienfach.
   *
   * @param fach Das umzuschaltende Studienfach
   */
  private removeFach(fach: Studienfach) {
    let index = this.selektierteStudienfaecher.indexOf(fach);
    if (index < 0) {
      this.selektierteStudienfaecher.push(fach);
    } else {
      this.selektierteStudienfaecher.splice(index, 1);
    }

    // STUDISU-45
    // falls der letzte studienbereich/das letzte suchwort geloescht wurde,
    // sollen die selektierten facettenfilter verworfen werden
    if (
      !this.urlParamService.isSuchwortSet() &&
      this.selektierteStudienfelder.length + this.selektierteStudienfaecher.length === 0
    ) {
      this.urlParamService.resetFilter();
    }

    this.updateUrlParamFach();
  }

  /**
   * Lädt die Studienfeldgruppen und fügt die Icons hinzu.
   */
  private ladeStudienfeldgruppen(studienfeldgruppen: Studienfeldgruppe[]) {
    this.studienfeldgruppen = studienfeldgruppen.map((studienfeldgruppe) => {
      studienfeldgruppe.icon = 'HA' + studienfeldgruppe.key;
      return studienfeldgruppe;
    });
  }

  /**
   * Helper-Funktion zum Parsen der Studienfeldgruppen aus der Url.
   *
   * @param studienfelderParams QueryParams
   */
  private parseUrlParams(studienfelderParams: any) {
    let studienfelderParamValue: string = studienfelderParams.studienfelder;
    let studienfaecherParamValue: string = studienfelderParams.studienfaecher;

    //    Felder sfe
    let studienfelderParamValues: string[] = studienfelderParamValue
      ? studienfelderParamValue.split(UrlParamService.VALUE_SEPARATOR)
      : [];

    // Genau die Studienfelder in die Selektion, die in der URL vorkommen.
    this.selektierteStudienfelder = this.studienfeldgruppen
      .map((studienfeldgruppen: Studienfeldgruppe) => studienfeldgruppen.studienfelder)

      .reduce((previousValue: Studienfeld[], currentValue: Studienfeld[]) =>
        previousValue.concat(currentValue)
      )
      .filter((studienfeld: Studienfeld) => {
        let res = studienfeld.dkzIds.filter(
          (dkzId: number) => studienfelderParamValues.indexOf('' + dkzId) > -1
        ).length;
        return res > 0;
      });

    // STUDISU-92:
    // Bei über 40 sfe und/oder sfa Parametern in der Url
    // sind beim Verarbeiten der Parameter zuerst die Studienfaecher
    // und erst anschliessend Studienfelder abzuschneiden.
    if (this.selektierteStudienfelder.length > MAX_STUDIENBEREICH_PARAMS) {
      this.selektierteStudienfelder = this.selektierteStudienfelder.slice(
        0,
        MAX_STUDIENBEREICH_PARAMS
      );
      this.loggingService.debug(
        'studienfelderdelegate.parseUrlParams() begrenzt studienfelder aus url'
      );
    }

    //    Faecher sfa
    let idList = studienfaecherParamValue
      ? studienfaecherParamValue.split(UrlParamService.VALUE_SEPARATOR)
      : [];
    this.selektierteStudienfaecher = [];
    this.studienfachService.getIds(idList).subscribe({
      next: (studienfaecher) => {
        this.selektierteStudienfaecher = studienfaecher;
        // Falls mehr als die maximale Anzahl Faecher in der url sind, werden nur die ersten MAX geladen
        if (this.selektierteStudienfaecher.length > MAX_STUDIENBEREICH_PARAMS) {
          this.loggingService.debug(
            'studienfelderdelegate.parseUrlParamseters() begrenzt studienfaecher aus url'
          );
          this.selektierteStudienfaecher = this.selektierteStudienfaecher.slice(
            0,
            MAX_STUDIENBEREICH_PARAMS
          );
        }

        // STUDISU-92:
        // Bei über 40 sfe und/oder sfa Parametern in der Url
        // sind beim Verarbeiten der Parameter zuerst die Studienfaecher
        // und erst anschliessend Studienfelder abzuschneiden.
        let anzahlSelektierteStudienbereiche =
          this.selektierteStudienfaecher.length + this.selektierteStudienfelder.length;
        if (anzahlSelektierteStudienbereiche > MAX_STUDIENBEREICH_PARAMS) {
          let anzahlFuerStudienfaecher =
            MAX_STUDIENBEREICH_PARAMS - this.selektierteStudienfelder.length;
          this.selektierteStudienfaecher = this.selektierteStudienfaecher.slice(
            0,
            anzahlFuerStudienfaecher
          );
          this.loggingService.debug(
            'studienfelderdelegate.parseUrlParams() begrenzt studienfaecher aus url wegen feldern'
          );
        }
      },
      complete: () => this.isReady.next(true)
    });
  }
}
