import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoggingService } from '@infosysbub/ng-lib-dpl3';
import { Observable, from as observableFrom } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SHOW_MODAL_ON_ERROR } from 'src/app/error/interceptor.token';
import { StudisuError } from 'src/app/error/studisu-error';
import { StudisuConfig, getStudisuConfig } from '../../../config/studisu.config';
import { ISelectItem } from './model/ISelectItem';
import { Studienfach } from './model/Studienfach';
import { UrlParamService } from './url-param.service';

@Injectable()
export class StudienfachService {
  private conf: StudisuConfig = getStudisuConfig();
  private studienfachIdsParam = 'sfa-ids';
  private lastIds = '';
  private lastResult = new Array<Studienfach[]>();

  constructor(
    private http: HttpClient,
    private urlParamService: UrlParamService,
    private loggingService: LoggingService
  ) {}

  public suchen(searchTerm: string): Observable<ISelectItem[]> {
    const url = this.conf.studienangeboteServiceHost + this.conf.studienfaecherServicePathAc;
    return this.urlParamService.currentParams.pipe(
      map(() => this.urlParamService.getUrlParamsForBackend({ ac: searchTerm })),
      switchMap((params) => this.loadStudienfaecher(url, params))
    );
  }

  public getIds(idList: string[]): Observable<Studienfach[]> {
    if (idList.length === 0) {
      return observableFrom(new Array<Studienfach[]>());
    }
    let ids = idList.join(UrlParamService.VALUE_SEPARATOR);

    // Verhindert Neuladen der Ergebnisse beim Wechsel des Suchtyps
    if (ids === this.lastIds) {
      return observableFrom(this.lastResult);
    }

    const url = this.conf.studienangeboteServiceHost + this.conf.studienfaecherServicePath;
    const params = new HttpParams().set(this.studienfachIdsParam, ids);
    this.loggingService.info(`SERVICE CALL ${url}?${params.toString()}`);
    return this.loadStudienfaecher(url, params);
  }

  /**
   * Lädt die Studienfaecher aus der uebergeben URL und liefert das Observable mit der Liste zurueck.
   *
   * @param url Die zu verwendende URL
   * @param params Http-Parameter
   * @return Observable<Studienfach[]>
   */
  private loadStudienfaecher(url: string, params: HttpParams): Observable<Studienfach[]> {
    return this.http
      .get(url, { params, context: new HttpContext().set(SHOW_MODAL_ON_ERROR, true) })
      .pipe(
        catchError((error) => {
          throw new StudisuError('Fehler beim laden der Studienfächer.', error);
        }),
        map((x: Studienfach[]): Studienfach[] => {
          const result = this.mapStudienfach(x);
          const ids = params.get(this.studienfachIdsParam);
          // Speichert IDs + Ergebnis des letzten Zugriffs wg. Flicker-Fixing Suchtyp-Wechsel
          if (ids) {
            this.lastIds = ids;
            this.lastResult = [result];
          }
          return result;
        })
      );
  }

  private mapStudienfach(studienfachJson: Studienfach[]): Studienfach[] {
    let result: Studienfach[] = [];
    studienfachJson.forEach((fach) => {
      result.push(new Studienfach(fach.dkzId, fach.name, fach.treffer, fach.dkzId, fach.teaser));
    });
    return result;
  }
}
