import { Messages } from '../../../../ui-components/model/Messages';
import { ISelectItem } from './ISelectItem';
import { Teaser } from './Teaser';

const TOKEN_NAME = '%name%';

/**
 * Representation eines Studienfach.
 */
export class Studienfach implements ISelectItem {
  constructor(
    public id: number,
    public name: string,
    public treffer: number,
    public dkzId: number,
    public teaser: Teaser | undefined
  ) {
    this._tooltip = '';
    let tooltip = Messages.TAG_STUDIENFACH_TOOLTIP_CLICK;
    if (tooltip && tooltip.indexOf(TOKEN_NAME) >= 0) {
      this._tooltip = tooltip.replace(TOKEN_NAME, this.name);
    }
  }

  _tooltip: string;

  get tooltip(): string {
    return this._tooltip;
  }

  get key(): string {
    if (typeof this.id === 'undefined') {
      this.id = this.dkzId;
    }
    return '' + this.id;
  }

  get label(): string {
    return this.name;
  }

  get value(): string {
    return '' + this.dkzId;
  }

  get icon(): string {
    return 'learning';
  }

  get clickable(): boolean {
    return true;
  }
}
