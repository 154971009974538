import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SHOW_MODAL_ON_ERROR } from 'src/app/error/interceptor.token';
import { StudisuError } from 'src/app/error/studisu-error';
import { StudisuConfig, getStudisuConfig } from '../../../config/studisu.config';
import { ServiceConstants } from '../../../services/serviceconstants';
import { ISelectItem } from '../../suche/services/model/ISelectItem';
import { UrlParamService } from '../../suche/services/url-param.service';
import { Studienfeld } from './model/Studienfeld';
import { Studienfeldgruppe } from './model/Studienfeldgruppe';

/**
 * Service für die Ermittlung der Studienfelder vom Backend.
 */
@Injectable()
export class StudienfelderService {
  private conf: StudisuConfig = getStudisuConfig();
  private studienfelder: Observable<Studienfeldgruppe[]> = null;

  constructor(
    private http: HttpClient,
    private urlParamService: UrlParamService
  ) {}

  /**
   * Holt ein Objekt, dessen 'studienbereiche' Attribut das Array mit den Studienbereichen enthält.
   */
  public getStudienfelder(): Observable<Studienfeldgruppe[]> {
    if (null === this.studienfelder) {
      const url = this.conf.studienangeboteServiceHost + this.conf.studienfelderServicePath;
      this.studienfelder = this.http
        .get(url, {
          headers: this.getHeaders(),
          context: new HttpContext().set(SHOW_MODAL_ON_ERROR, true)
        })
        .pipe(
          map((response) => this.mapResponse(response)),
          catchError((error) => {
            throw new StudisuError('Fehler beim Laden der Studienfelder.', error);
          })
        );
    }
    return this.studienfelder;
  }

  /**
   * Service-Methode die aus dem Backend Studienfelder für ein Studienfach abruft
   * @param studienfach-Id, die gesucht wird, als String
   * @return studienfelder Observable<String[]> die für den gesuchte Studienfach gefunden wurden
   */
  public getStudienfelderForStudienfach(studienfach: number): Observable<String[]> {
    const url =
      this.conf.studienangeboteServiceHost + this.conf.studienfelderByStudienfachServicePath;
    const params = new HttpParams().set(UrlParamService.PARAM_STUDIENFAECHER, `${studienfach}`);
    return this.http
      .get(url, {
        headers: this.getHeaders(),
        params,
        context: new HttpContext().set(SHOW_MODAL_ON_ERROR, true)
      })
      .pipe(
        map((x) => <String[]>x),
        catchError((error) => {
          throw new StudisuError('Fehler beim laden der Studienfelder für ein Studienfach.', error);
        })
      );
  }

  /**
   * Suche nach einem Teilstring in den Studienfeldern.
   *
   * @param searchTerm Gesuchter Teilstring
   */
  public suchen(searchTerm: string): Observable<Studienfeld[]> {
    let lcSearchTerm = searchTerm.toLocaleLowerCase();
    return this.getStudienfelder().pipe(
      map((studienbereiche: Studienfeldgruppe[]) =>
        studienbereiche
          .map((studienbereich) =>
            studienbereich.studienfelder.filter(
              (studienfeld) => studienfeld.name.toLocaleLowerCase().indexOf(lcSearchTerm) > -1
            )
          )
          .reduce((acc, value) => acc.concat(value), [])
      )
    );
  }

  public suchenAc(searchTerm: string): Observable<ISelectItem[]> {
    const url = this.conf.studienangeboteServiceHost + this.conf.studienfelderServicePathAc;
    return this.urlParamService.currentParams.pipe(
      map(() => this.urlParamService.getUrlParamsForBackend({ ac: searchTerm })),
      switchMap((params) => this.loadStudienfelderAc(url, params))
    );
  }

  /**
   * Lädt die Studienfelder aus der uebergeben URL und liefert das Observable mit der Liste zurueck.
   *
   * @param url Die zu verwendende URL
   * @param params Request-Parameter
   * @return Observable<Studienfeld[]>
   */
  private loadStudienfelderAc(url: string, params: HttpParams): Observable<Studienfeld[]> {
    return this.http.get(url, { headers: this.getHeaders(), params }).pipe(
      catchError(() => throwError(() => ServiceConstants.SERVICE_NOT_AVAILABLE)),
      map((x) => this.mapStudienfeldAc(<Studienfeld[]>x))
    );
  }

  private mapStudienfeldAc(studienfeldJson: Studienfeld[]) {
    let result: Studienfeld[] = [];
    studienfeldJson.forEach((feld) => {
      result.push(
        new Studienfeld(feld.dkzId.toString(), feld.name, feld.dkzId, feld.treffer, feld.dkzId)
      );
    });
    return result;
  }

  /**
   * Takes a Response Object which should contain Studienfeldgruppe and map
   * them to real Studienfeldgruppe Objects. This will enable us to use the interface methods of the
   * Studienfeldgruppe class. This will also map Studienfelder inside the Studienfeldgruppe.
   * If used with existing Studienfeldgruppe Objects they are just recreated.
   * @param response
   */
  private mapResponse(data) {
    let result: Studienfeldgruppe[] = [];
    let felder: Studienfeldgruppe[] = data.studienfeldgruppen;
    felder.forEach((feld) => {
      result.push(
        new Studienfeldgruppe(
          feld.key,
          feld.name,
          feld.dkzIds,
          this.mapStudienfelder(feld.studienfelder),
          feld.icon
        )
      );
    });
    return result;
  }

  /**
   * Takes an array of studienfeld items (which are castet from any usually) and map
   * them to real Studienfeld Objects. This will enable us to use the interface methods of the
   * Studienfeld class.
   * If used with existing Studienfeld Objects they are just recreated.
   * @param felder array of type any castet to Studienfeld[]
   */
  private mapStudienfelder(felder: Studienfeld[]) {
    let result: Studienfeld[] = [];
    felder.forEach((feld) => {
      result.push(new Studienfeld(feld.key, feld.name, feld.dkzIds));
    });

    return result;
  }

  /**
   * Liefert die Header für die HTTP-Anfrage, hier für JSON-Ergebnisse.
   * @returns {HttpHeaders}
   */
  private getHeaders(): HttpHeaders {
    return new HttpHeaders({ Accept: 'application/json' });
  }
}
